var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex gap-5 w-full"
  }, [_c('div', {
    staticClass: "w-1/2 flex flex-col gap-8 border-r border-grey-neutral pr-5"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('div', {
    staticClass: "flex gap-2 border-b pb-2 border-grey-neutral"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  }), _c('h1', {
    staticClass: "font-semibold text-grey-2"
  }, [_vm._v("Student Info")])], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Name",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Name"
    },
    model: {
      value: _vm.studentData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "name", $$v);
      },
      expression: "studentData.name"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Email",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Email"
    },
    model: {
      value: _vm.studentData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "email", $$v);
      },
      expression: "studentData.email"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Phone Number",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Phone Number"
    },
    model: {
      value: _vm.studentData.hp,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "hp", $$v);
      },
      expression: "studentData.hp"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Customer",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Customer"
    },
    model: {
      value: _vm.studentData.customer_name,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "customer_name", $$v);
      },
      expression: "studentData.customer_name"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('div', {
    staticClass: "flex gap-2 border-b pb-2 border-grey-neutral"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  }), _c('h1', {
    staticClass: "font-semibold text-grey-2"
  }, [_vm._v("Prakerja Class Info")])], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Voucher Code",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Voucher Code"
    },
    model: {
      value: _vm.studentData.voucher_code,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "voucher_code", $$v);
      },
      expression: "studentData.voucher_code"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Redemption Code",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Redemption Code"
    },
    model: {
      value: _vm.studentData.redeem_code_pmo,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "redeem_code_pmo", $$v);
      },
      expression: "studentData.redeem_code_pmo"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Invoice Code",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Invoice Code"
    },
    model: {
      value: _vm.studentData.invoice_code_pmo,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "invoice_code_pmo", $$v);
      },
      expression: "studentData.invoice_code_pmo"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Digital Customer",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Digital Customer"
    },
    model: {
      value: _vm.studentData.ecommerce,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "ecommerce", $$v);
      },
      expression: "studentData.ecommerce"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Digital Platform Redemption Report Time",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Digital Platform Redemption Report Time"
    },
    model: {
      value: _vm.redemptionReportTime,
      callback: function callback($$v) {
        _vm.redemptionReportTime = $$v;
      },
      expression: "redemptionReportTime"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Digital Platform Completion Report Time",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "Digital Platform Completion Report Time"
    },
    model: {
      value: _vm.completionReportTime,
      callback: function callback($$v) {
        _vm.completionReportTime = $$v;
      },
      expression: "completionReportTime"
    }
  })], 1)]), _c('div', {
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('div', {
    staticClass: "flex gap-2 border-b pb-2 border-grey-neutral"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  }), _c('h1', {
    staticClass: "font-semibold text-grey-2"
  }, [_vm._v("Lms Class Info")])], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Program Type",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.studentData.program_code,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "program_code", $$v);
      },
      expression: "studentData.program_code"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Class Code",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.studentData.core_class_code,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "core_class_code", $$v);
      },
      expression: "studentData.core_class_code"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Class Name",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.studentData.core_class_name,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "core_class_name", $$v);
      },
      expression: "studentData.core_class_name"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Class Schedule Name",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.studentData.lms_class_name,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "lms_class_name", $$v);
      },
      expression: "studentData.lms_class_name"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Student Registered Time",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.registeredTime,
      callback: function callback($$v) {
        _vm.registeredTime = $$v;
      },
      expression: "registeredTime"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Batch",
      "borderEnabled": true,
      "disabled": "",
      "placeholder": "-"
    },
    model: {
      value: _vm.studentData.batch,
      callback: function callback($$v) {
        _vm.$set(_vm.studentData, "batch", $$v);
      },
      expression: "studentData.batch"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="flex gap-5 w-full">
    <div class="w-1/2 flex flex-col gap-8 border-r border-grey-neutral pr-5">
      <!-- STUDENT INFO -->
      <div class="flex flex-col gap-4">
        <div class="flex gap-2 border-b pb-2 border-grey-neutral">
          <Label color="yellow" />
          <h1 class="font-semibold text-grey-2">Student Info</h1>
        </div>
        <TextField type="text" label="Name" :borderEnabled="true" disabled placeholder="Name" v-model="studentData.name" />
        <TextField type="text" label="Email" :borderEnabled="true" disabled placeholder="Email" v-model="studentData.email" />
        <TextField type="text" label="Phone Number" :borderEnabled="true" disabled placeholder="Phone Number" v-model="studentData.hp" />
        <TextField type="text" label="Customer" :borderEnabled="true" disabled placeholder="Customer" v-model="studentData.customer_name" />
      </div>
      <!-- STUDENT INFO -->
      <!-- PRAKERJA CLASS INFO -->
      <div class="flex flex-col gap-4">
        <div class="flex gap-2 border-b pb-2 border-grey-neutral">
          <Label color="yellow" />
          <h1 class="font-semibold text-grey-2">Prakerja Class Info</h1>
        </div>
        <TextField type="text" label="Voucher Code" :borderEnabled="true" disabled placeholder="Voucher Code" v-model="studentData.voucher_code" />
        <TextField type="text" label="Redemption Code" :borderEnabled="true" disabled placeholder="Redemption Code" v-model="studentData.redeem_code_pmo" />
        <TextField type="text" label="Invoice Code" :borderEnabled="true" disabled placeholder="Invoice Code" v-model="studentData.invoice_code_pmo" />
        <TextField type="text" label="Digital Customer" :borderEnabled="true" disabled placeholder="Digital Customer" v-model="studentData.ecommerce" />
        <TextField type="text" label="Digital Platform Redemption Report Time" :borderEnabled="true" disabled placeholder="Digital Platform Redemption Report Time" v-model="redemptionReportTime" />
        <TextField type="text" label="Digital Platform Completion Report Time" :borderEnabled="true" disabled placeholder="Digital Platform Completion Report Time" v-model="completionReportTime" />
      </div>
      <!-- PRAKERJA CLASS INFO -->
    </div>
    <div class="w-1/2">
      <!-- LMS CLASS INFO -->
      <div class="flex flex-col gap-4">
        <div class="flex gap-2 border-b pb-2 border-grey-neutral">
          <Label color="yellow" />
          <h1 class="font-semibold text-grey-2">Lms Class Info</h1>
        </div>
        <TextField type="text" label="Program Type" :borderEnabled="true" disabled placeholder="-" v-model="studentData.program_code" />
        <TextField type="text" label="Class Code" :borderEnabled="true" disabled placeholder="-" v-model="studentData.core_class_code" />
        <TextField type="text" label="Class Name" :borderEnabled="true" disabled placeholder="-" v-model="studentData.core_class_name" />
        <TextField type="text" label="Class Schedule Name" :borderEnabled="true" disabled placeholder="-" v-model="studentData.lms_class_name" />
        <TextField type="text" label="Student Registered Time" :borderEnabled="true" disabled placeholder="-" v-model="registeredTime" />
        <TextField type="text" label="Batch" :borderEnabled="true" disabled placeholder="-" v-model="studentData.batch" />
      </div>
      <!-- LMS CLASS INFO -->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'student-report-detail',
  props: {
    studentData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  },
  computed: {
    redemptionReportTime() {
      return this.formatDate(this.studentData?.reported_date)
    },
    completionReportTime() {
      return this.formatDate(this.studentData?.completion_date)
    },
    registeredTime() {
      return this.formatDate(this.studentData?.created_date)
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '-'
      return dayjs(date).format('DD MM YYYY HH:mm')
    }
  }
}
</script>

<style>
</style>